
import { Vue, Component } from "vue-property-decorator"
import UserAvatarGroup from "@/components/widgets/common/UserAvatarGroup.vue"
import AppPage from "@/components/layout/AppPage.vue"
import Icon from "@/components/widgets/icons/Icon.vue"
import InlineTaskCard from "@/components/client/Dashboard/InlineTaskCard.vue"
import MissionUpdates from "@/components/consultant/MissionUpdates.vue"
import PendingPaymentCard from "@/components/client/Dashboard/PendingPaymentCard.vue"
import UnreadMessages from "@/components/client/Dashboard/UnreadMessages.vue"
import { MyClientQuery, MyClientQueryQuery, UnreadConversationsQuery } from "@/gql"
import { PageState } from "@/types"
import { NetworkStatus, ApolloQueryResult } from "@apollo/client/core"
import { useAccountContext } from "@/hooks/useAccountContext"
import CreateNewProject from "@/modules/Projects/CreateNewProject/CreateNewProject.vue"

@Component({
  components: {
    UserAvatarGroup,
    AppPage,
    Icon,
    InlineTaskCard,
    MissionUpdates,
    PendingPaymentCard,
    UnreadMessages,
    CreateNewProject,
  },
  apollo: {
    client: {
      query: MyClientQuery,
      variables() {
        return {
          id: this.clientId,
        }
      },
      result(result) {
        this.onClientDetailsResults(result)
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loadingClientDetails = false
          this.loadingError = true
          this.pageState = PageState.Error
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.myClient || {}
      },
    },
    unreadConversations: {
      query: UnreadConversationsQuery,
      variables() {
        return {
          clientId: this.clientId,
        }
      },

      error(error) {
        if (error.graphQLErrors) {
          this.loadingClientDetails = false
          this.loadingError = true
          this.pageState = PageState.Error
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.unreadConversations || []
      },
    },
  },
})
export default class ClientDashboard extends Vue {
  PageState = PageState

  pageState = PageState.Loaded
  loadingClientDetails = true
  loadingError = false
  networkError = false
  resolveLoading = false
  showApproveDialog = false

  openCreateProject = false

  get clientId() {
    const { client } = useAccountContext()
    return client?.prn
  }

  onClientDetailsResults(result: ApolloQueryResult<MyClientQueryQuery>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.pageState = PageState.Loaded

      this.loadingClientDetails = false
    } else {
      this.pageState = PageState.Error
    }
  }
}
