
import { Component, Prop, Vue } from "vue-property-decorator"
import {
  ConsultantAvailability,
  ConsultantProfileFragmentFragment,
  UpdateConsultantAvailabilityMutation,
  UpdateConsultantAvailabilityMutationMutation,
} from "@/gql"
import { ConsultantAvailabilities } from "@/constants"
import AvailabilityBadge from "@/components/consultant/AvailabilityBadge.vue"
import moment from "moment"

enum ConsultantNextAvailability {
  nextMonth = "1",
  nextTwoMonths = "2",
  nextThreeMonths = "3",
}

@Component({
  components: {
    AvailabilityBadge,
  },
})
export default class Availability extends Vue {
  @Prop() readonly consultantProfile?: ConsultantProfileFragmentFragment
  @Prop() readonly loadingData!: boolean

  ConsultantAvailabilityOption = ConsultantAvailability
  fullNameCharactersLimit = 12

  showEditAvailabilityForm = false
  loading = false
  consultantAvailabilities = ConsultantAvailabilities

  form: { [key: string]: string } = {
    availability: "",
    availabilityReason: "",
    nextAavailability: "",
  }

  onEditAvailability() {
    this.showEditAvailabilityForm = true
  }

  get nextAvailabilityList() {
    return [
      { text: "4-6 weeks", value: ConsultantNextAvailability.nextMonth },
      { text: "8-12 weeks", value: ConsultantNextAvailability.nextTwoMonths },
      { text: "3-6 months", value: ConsultantNextAvailability.nextThreeMonths },
    ]
  }

  get availabilityText() {
    return (
      ConsultantAvailabilities.find(
        (status) => status.value === this.consultantProfile?.availability
      )?.text || "Not Specified"
    )
  }

  get availabilityColor() {
    switch (this.consultantProfile?.availability) {
      case ConsultantAvailability.AvailableFullTime:
        return "#219653"
      case ConsultantAvailability.AvailablePartTime:
        return "orange"
      case ConsultantAvailability.NotAvailable:
      default:
        return "red"
    }
  }

  getBackgroundColor(value: ConsultantAvailability) {
    switch (value) {
      case ConsultantAvailability.AvailableFullTime:
        return "#e9ffe5"
      case ConsultantAvailability.AvailablePartTime:
        return "#FFD580"
      case ConsultantAvailability.NotAvailable:
      default:
        return "#FF7F7F"
    }
  }

  get nextAvailableDate() {
    return new Date(
      new Date().setMonth(new Date().getMonth() + Number(this.form.nextAavailability))
    )
  }

  async updateAvailability() {
    if (!this.consultantProfile) return

    this.loading = true
    if (!this.form.availability) {
      this.loading = false
      this.addError("Availability cannot be empty")
      return
    }
    try {
      const result = await this.mutate<UpdateConsultantAvailabilityMutationMutation>({
        mutation: UpdateConsultantAvailabilityMutation,
        variables: {
          availability: this.form.availability,
          availabilityReason: this.form.availabilityReason || undefined,
          consultantId: this.can("edit_talent") ? this.consultantProfile.id : undefined,
          nextAvailability: moment(this.nextAvailableDate),
        },
      })

      if (result && result.data && result.data.updateConsultantAvailability) {
        const error = result.data.updateConsultantAvailability.error

        if (error) {
          this.addMutationError(error)
          this.$emit("error", error)
        } else {
          if (result.data.updateConsultantAvailability) {
            this.loading = false
            this.showEditAvailabilityForm = false
            this.addSuccess("Your availability has been updated")
          }
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    }
  }
}
