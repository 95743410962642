
import { MissionContractPayment } from "@/gql"
import { Component, Vue, Prop } from "vue-property-decorator"
import moment from "moment"

@Component({})
export default class PendingPaymentCard extends Vue {
  @Prop({ required: true }) readonly payment!: MissionContractPayment

  get dueDate() {
    return moment(this.payment.dueDate).format("Do MMMM, YYYY")
  }
}
